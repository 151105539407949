import React from "react";
import { graphql } from "gatsby";
import Home from "@views/Home";
import { HomePageProps } from "src/types";

export const pageQuery = graphql`
  query HomeQuery {
    # BG HERO IMAGE DESKTOP
    heroDesktop: file(relativePath: { eq: "bg/desktop/home-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2024, maxHeight: 1047, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # BG HERO IMAGE MOBILE
    heroMobile: file(relativePath: { eq: "bg/mobile/home-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 751, maxHeight: 1334, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # CS IMAGE
    csImg: file(relativePath: { eq: "cs.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 845, maxHeight: 366, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # BG PROJECT DESKTOP
    bgProjectDesktop: file(
      relativePath: { eq: "bg/desktop/home-chart-bg.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2024, maxHeight: 1047, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # BG PROJECT MOBILE
    bgProjectMobile: file(relativePath: { eq: "bg/mobile/home-chart-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 751, maxHeight: 1334, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # CHART IMAGE
    chart: file(relativePath: { eq: "bar-chart-2024.png" }) {
      childImageSharp {
        fluid(maxWidth: 740, maxHeight: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # PRODUCTS IMAGE
    products: allFile(
      filter: { dir: { regex: "/(products)/" }, name: { regex: "/(seragam)/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          base
          childImageSharp {
            fluid(maxWidth: 557, maxHeight: 778, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    # SERVICES ICON
    services: allFile(
      filter: {
        dir: { regex: "/(icon)/(services)/" }
        name: { regex: "/(icon)/" }
      }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }

    # GUIDE BANNER IMAGE
    guideGirl: file(relativePath: { eq: "pemandu-bahan-ukuran.png" }) {
      childImageSharp {
        fluid(maxWidth: 549, maxHeight: 454, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # CLIENT LOGO
    clientLogo: allFile(
      filter: { dir: { regex: "/(klien-kami)/" }, name: { regex: "/()/" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          id
          base
          childImageSharp {
            fluid(maxHeight: 100, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default function Index(props: HomePageProps) {
  return <Home {...props} />;
}
